
import * as _ from "lodash";
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import store from '@/store'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import SelectionBox from '@/components/StudyCreation/SelectionBox.vue'
import ViabilityCalculator from '@/components/StudyCreation/ViabilityCalculator.vue'
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
import InfoMessage from '@/components/StudyCreation/InfoMessage.vue'
import UsersOriginSelector from '@/components/StudyCreation/UsersOriginSelector.vue'
import Input from '~/components/Input/Input.vue'
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name : 'Step3WatLab',
  components:{
    StudyCreationBase,
    Separation,
    ViabilityCalculator,
    SelectionBox,
    ProgressModal,
    InfoMessage,
    Input,
    UsersOriginSelector
  },
  setup() {
  const router = useRouter()
  const route = useRoute()

  return {
    router,
    route
  }
},
  props:{
    STEP:[String, Number]
  },
  computed:{
    ...mapGetters([
      'watlabSteps'
    ])
  },
  data(){
    return{
      watLabData:{
        planName:'',
        duration:1,
        price:0,
        urlToken: '',
        language: 'es',
        country: {elementId: 0},
        usersOrigin:'WAT',
        dateFrom:'11 de octubre de 2023',
        dateTo: '11 de octubre de 2023',
        title:''
      },
      error: false,
      errorMessage:'',
      showProgress: false,
      isMobile: false,
      showCalculatorMobile: false,
      loadingViability: false,
      showExample: false
    }
  },
  methods:{
    checkUrlToken(event: any){
      this.watLabData.urlToken = this.watLabData.urlToken.replace(/\W/g, "");
    },
    beforeStep4():any{
      this.error = false
      if(this.watLabData.urlToken.length < 2){
        this.error = true
        this.errorMessage = this.$t('suite_watlab_step3_error_url')
        return
      }
      const data = {
        ...this.watLabData,
        contractId: this.route.params.id
      }
      this.showProgress = true
      store.dispatch('saveWatLabConfiguration', data).then((data) => {
        this.showProgress = false
        this.router.push({name:'newstudy.step4WatLab', params:{ id: this.route.params.id, testId: this.route.params.testId }})
      }).catch((response)  => {
        this.error = true
        this.showProgress = false
        this.errorMessage = this.$t('suite_swal_info_save_error')
        if (response.data.payload != null && response.data.payload != undefined) {
          if (response.data.payload == 'URL Token already exists') this.errorMessage = this.$t('suite_swal_watlab_url_error')
        }
      })
    },
    async fetchWatLab(){
      await store.dispatch('fetchWatLabConfiguration',{contractId:this.route.params.id}).then((data) => {
        this.watLabData.duration = data.duration != undefined ? data.duration : this.watLabData.duration
        this.watLabData.planName = data.planName != undefined ? data.planName : this.watLabData.planName
        this.watLabData.price = data.price != undefined ? data.price : this.watLabData.price
        this.watLabData.urlToken = data.urlToken != undefined ? data.urlToken : this.watLabData.urlToken
        this.watLabData.language = data.language != undefined ? data.language : this.watLabData.language
        this.watLabData.country = data.country != undefined ? data.country : this.watLabData.country
        this.watLabData.usersOrigin = data.usersOrigin != undefined ? data.usersOrigin : this.watLabData.usersOrigin
        this.watLabData.dateFrom = data.dateFrom != undefined ? data.dateFrom : this.watLabData.dateFrom
        this.watLabData.dateTo = data.dateTo != undefined ? data.dateTo : this.watLabData.dateTo
        this.watLabData.title = data.title != undefined ? data.title : data.studyName
      })
    },
    resize(){
      if (window.innerWidth <=875) {
        this.isMobile = true
      }
      else{
        this.isMobile = false
        this.showCalculatorMobile = true
      }
    },
    async fetchPlansData(){
      await store.dispatch('fetchWatLabPlans')
    },
  },
  mounted(){
    this.fetchPlansData()
    this.fetchWatLab()
    $("html, body").animate({ scrollTop: 0 }, 400);
    const vm = this
    this.resize()
    $(window).resize(function(){
       vm.resize()
    });
  }
})
